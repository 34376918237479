import React, {useEffect} from 'react'
import {useLocation, Navigate} from 'react-router-dom'
import apiLink from '../../config'

import loading_Data from '../../assets/loading-Data.gif'
import Entete from '../MEP/entete' 
import OADMenu from '../MEP/oadMenu'
import ButtonQuestions from '../MEP/button question'
import GraphSolIrrig from './Graphiques/graphSolIrrigation'
import GraphSolEsquive from './Graphiques/graphSolEsquive'
import GraphSolEsquiveViti from './Graphiques/graphSolEsquiveViti'
import GraphAssolement3 from './Graphiques/assolement_graph_3';

import IRRIG_ac from '../../assets/navig_irrigation_ac.png'
import ESQ_ac from '../../assets/navig_esquive culture_ac.png'
import CCult_ac from '../../assets/navig_remplace culture_ac.png'
import CCep_ac from '../../assets/navig_solution_remplace_cepage_ac.png'
import IRRIG_inac from '../../assets/navig_irrigation_inac.png'
import ESQ_inac from '../../assets/navig_esquive culture_inac.png'
import CCult_inac from '../../assets/navig_remplace culture_inac.png'
import CCep_inac from '../../assets/navig_solution_remplace_cepage_inac.png'

function Solutions2050() {
    const { state } = useLocation();
    const { idExploit } = state;
    const [CityName, setCityName] = React.useState("")
    const [libellPRA, setLibellePRA] = React.useState("")
    const [ANNEE, setANNEE] = React.useState("2025")
    const [BIRRIG, setBIRRIG] = React.useState(true)
    const [BESQ, setBESQ] = React.useState(false)
    const [BCCult, setBCCult] = React.useState(false)
    const [BCCep, setBCCep] = React.useState(false)
    const [B2d, setB2d]= React.useState('Checked')
    const [B4d, setB4d]= React.useState('UnChecked')
    const [TypeCulture, setTypeCulture] = React.useState("118")

    const [dataBE_1122_2D, setdataBE_1122_2D]= React.useState()
    const [dataBEC_1122_2D, setdataBEC_1122_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_1123_2D, setdataBE_1123_2D]= React.useState()
    const [dataBEC_1123_2D, setdataBEC_1123_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_1112_2D, setdataBE_1112_2D]= React.useState()
    const [dataBEC_1112_2D, setdataBEC_1112_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_1113_2D, setdataBE_1113_2D]= React.useState()
    const [dataBEC_1113_2D, setdataBEC_1113_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_114_2D, setdataBE_114_2D]= React.useState()
    const [dataBEC_114_2D, setdataBEC_114_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_115_2D, setdataBE_115_2D]= React.useState()
    const [dataBEC_115_2D, setdataBEC_115_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_118_2D, setdataBE_118_2D]= React.useState()
    const [dataBEC_118_2D, setdataBEC_118_2D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])

    const [dataBE_1122_4D, setdataBE_1122_4D]= React.useState() 
    const [dataBEC_1122_4D, setdataBEC_1122_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_1123_4D, setdataBE_1123_4D]= React.useState()
    const [dataBEC_1123_4D, setdataBEC_1123_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_1112_4D, setdataBE_1112_4D]= React.useState()
    const [dataBEC_1112_4D, setdataBEC_1112_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_1113_4D, setdataBE_1113_4D]= React.useState()
    const [dataBEC_1113_4D, setdataBEC_1113_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_114_4D, setdataBE_114_4D]= React.useState()
    const [dataBEC_114_4D, setdataBEC_114_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_115_4D, setdataBE_115_4D]= React.useState()
    const [dataBEC_115_4D, setdataBEC_115_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataBE_118_4D, setdataBE_118_4D]= React.useState()
    const [dataBEC_118_4D, setdataBEC_118_4D]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])

    const [dataELib_1112, setdataELib1112] = React.useState(["15/02", "20/02", "25/02", "05/03", "10/03", "15/03"])
    const [dataELib_1113, setdataELib1113] = React.useState(["25/10", "30/10", "05/11", "10/11", "15/11", "20/11", "25/11"])
    const [dataELib_1122, setdataELib1122] = React.useState(["15/02", "20/02", "25/02", "05/03", "10/03", "15/03"])
    const [dataELib_1123, setdataELib1123] =  React.useState(["25/10", "30/10", "05/11", "10/11", "15/11", "20/11", "25/11"])
    const [dataELib_114, setdataELib114] = React.useState(["15/02", "20/02", "25/02", "05/03", "10/03", "15/03"])
    const [dataELib_115, setdataELib115] =  React.useState(["25/10", "30/10", "05/11", "10/11", "15/11", "20/11", "25/11"])
    const [dataELib_118, setdataELib118] = React.useState(["15/03", "20/03", "25/03", "30/03", "05/04", "10/04", "15/04", "20/04", "25/04", "30/04", "05/05", "10/05", "15/05", "20/05", "25/05", "30/05"])
    const [dataELib_222, setdataELib222] = React.useState(["15/08", "20/08", "25/08", "30/08", "05/09"])

     
    const generateRandomData = (nb) => {
        const newData = [];
        for (let i = 0; i < nb; i++) {
          const innerArray = [];
          for (let j = 0; j < 7; j++) {
            innerArray.push(Math.floor(Math.random() * 31) + 5);
          }
          newData.push(innerArray);
        }
        return newData;
      }

    const [dataE_1112_2D, setdataE_1112_2D]= React.useState(generateRandomData(6))
    const [dataE_1113_2D, setdataE_1113_2D]= React.useState(generateRandomData(7))
    const [dataE_1122_2D, setdata_E1122_2D]= React.useState(generateRandomData(6))
    const [dataE_1123_2D, setdata_E1123_2D]= React.useState(generateRandomData(7))
    const [dataE_114_2D, setdata_E114_2D]= React.useState(generateRandomData(6))
    const [dataE_115_2D, setdata_E115_2D]= React.useState(generateRandomData(7))
    const [dataE_118_2D, setdata_E118_2D]= React.useState(generateRandomData(16))
    const [dataE_222_2D, setdata_E222_2D]= React.useState(generateRandomData(5))

    const [dataE_1112_4D, setdataE_1112_4D]= React.useState(generateRandomData(6))
    const [dataE_1113_4D, setdataE_1113_4D]= React.useState(generateRandomData(7))
    const [dataE_1122_4D, setdata_E1122_4D]= React.useState(generateRandomData(6))
    const [dataE_1123_4D, setdata_E1123_4D]= React.useState(generateRandomData(7))
    const [dataE_114_4D, setdata_E114_4D]= React.useState(generateRandomData(6))
    const [dataE_115_4D, setdata_E115_4D]= React.useState(generateRandomData(7))
    const [dataE_118_4D, setdata_E118_4D]= React.useState(generateRandomData(16))
    const [dataE_222_4D, setdata_E222_4D]= React.useState(generateRandomData(5))



    const [dataBE, setdataBE]= React.useState()
    const [dataBEC, setdataBEC]= React.useState([[425,455,490,525],[475,500,550,600],[500,525,550,617],[510,550,600,650]])
    const [dataE, setdataE]= React.useState(generateRandomData(6))
    const [maxBE, setMaxBE] = React.useState()
    const [dataBECombine, setDataBECombine] = React.useState()
    const [dataELib, setdataELib]= React.useState(["15/02", "20/02", "25/02", "05/03", "10/03", "15/03"])

    const [myDataSurf1, setMyDataSurf1]= React.useState( 
        [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
        {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
        {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
        {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
        {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
    const [myDataSurf2, setMyDataSurf2]= React.useState(
        [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
        {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
        {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
        {"name":"Blé tendre de printemps\n16.43 ha","value":"16.43","itemStyle":{"color":"#FFD705"}},
        {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
    const [myDataPB1, setMyDataPB1]= React.useState(
        [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
        {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
        {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
        {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
        {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}]
       )
    const [myDataPB2, setMyDataPB2]= React.useState(
        [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
        {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
        {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
        {"name":"Blé tendre de printemps\n24055 ","value":24055,"itemStyle":{"color":"#FFD705"}},
        {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
    const [surfTot1, setSurfTot1]= React.useState(68.99)
    const [surfTot2, setSurfTot2]= React.useState(68.99)
    const [PBTot1, setPBTot1]= React.useState(63881)
    const [PBTot2, setPBTot2]= React.useState(72658)
    const [DataDifRisque_euros, setDataDifRisque_euros]= React.useState([1500,-700,2900])
    const [DataDifRisque_pourcent, setDataDifRisque_pourcent]= React.useState([2,0.9,9])
    const [ShowResult, setShowResult] = React.useState(true)
    const [TypeCultureRemplacer, setTypeCultureRemplacer] = React.useState('115')
    const [TypeCulturePar, setTypeCulturePar] = React.useState('1112')


    const [dataCCep, setdataCCep]= React.useState()
    const [dataCCepLib, setdataCCepLib]= React.useState()
   

    function fetchData() {
        fetch('https://api2050.diagorisk.com/pageSolutionIrrig/?idExploitation=' + idExploit)
          .then((response) => response.json())
          .then((response) => {
            if ('error' in response) {
              // Si la réponse contient une erreur, relancer la requête après 60 secondes
              setTimeout(fetchData, 60000);
            } else {
              // Si la réponse est réussie, mettre à jour les données
              setdataBE_1112_2D(response.dataBE_1112_2D)
                  setdataBE_1112_2D(prev => {
                    return prev;
                  })
                  setdataBE_1113_2D(response.dataBE_1113_2D)
                  setdataBE_1113_2D(prev => {
                    return prev;
                  })
                  setdataBE_1122_2D(response.dataBE_1122_2D)
                  setdataBE_1122_2D(prev => {
                    return prev;
                  })
                  setdataBE_1123_2D(response.dataBE_1123_2D)
                  setdataBE_1123_2D(prev => {
                    return prev;
                  })
                  setdataBE_114_2D(response.dataBE_114_2D)
                  setdataBE_114_2D(prev => {
                    return prev;
                  })
                  setdataBE_115_2D(response.dataBE_115_2D)
                  setdataBE_115_2D(prev => {
                    return prev;
                  })
                  setdataBE_118_2D(response.dataBE_118_2D)
                  setdataBE_118_2D(prev => {
                    setdataBE(prev)
                    return prev;
                  })
                  setdataBE_1112_4D(response.dataBE_1112_4D)
                  setdataBE_1112_4D(prev => {
                    return prev;
                  })
                  setdataBE_1113_4D(response.dataBE_1113_4D)
                  setdataBE_1113_4D(prev => {
                    return prev;
                  })
                  setdataBE_1122_4D(response.dataBE_1122_4D)
                  setdataBE_1122_4D(prev => {
                    return prev;
                  })
                  setdataBE_1123_4D(response.dataBE_1123_4D)
                  setdataBE_1123_4D(prev => {
                    return prev;
                  })
                  setdataBE_114_4D(response.dataBE_114_4D)
                  setdataBE_114_4D(prev => {
                    // Flatten the array of arrays into a single array
                    var flattenedData = prev.reduce((acc, curr) => acc.concat(curr), []);
                    // Find the maximum value in the flattened array
                    setMaxBE(Math.max(...flattenedData))
                    return prev;
                  })
                  setdataBE_115_4D(response.dataBE_115_4D)
                  setdataBE_115_4D(prev => {
                    return prev;
                  })
                  setdataBE_118_4D(response.dataBE_118_4D)
                  setdataBE_118_4D(prev => {
                    return prev;
                  })
            }
          })
          .catch((error) => {
            // Gérer les erreurs de la requête
            console.error('Erreur lors de la requête API :', error);
          });
        }

    useEffect ( () => {
        fetch(apiLink + 'getLocalisation/' + idExploit)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            var city =  response.idCommune
            fetch(apiLink + 'getCommune/' + city )
            .then( (response) => {
            return response.json()
            })
            .then(response =>{
            setLibellePRA(response.libellePetiteRegionAgricole)
            setLibellePRA(prev => {
                return prev;
            })
            var cp = response.codePostal
            var libellecity = response.libelleCommune
            setCityName(cp + ' - ' + libellecity)
            setCityName(prev => {
                return prev;
            })
            })
        }) 
        fetchData()
        fetch('https://api2050.diagorisk.com/pageSolutionRemplacerCepage/?idExploitation=' + idExploit)
          .then((response) => response.json())
          .then((response) => {
            setdataCCep(response.dataCCep)
            setdataCCep(prev => {
                return prev;
            })
            setdataCCepLib(response.dataCCepLib)
            setdataCCepLib(prev => {
                return prev;
            })
        })
      }, [idExploit])

      useEffect(() => {
        // Liste des variables de données
        const dataVariables = [
            dataBE_1112_2D,
            dataBE_1113_2D,
            dataBE_1122_2D,
            dataBE_1123_2D,
            dataBE_114_2D,
            dataBE_115_2D,
            dataBE_118_2D,
            dataBE_1112_4D,
            dataBE_1113_4D,
            dataBE_1122_4D,
            dataBE_1123_4D,
            dataBE_114_4D,
            dataBE_115_4D,
            dataBE_118_4D
        ];
    
        // Filtrer les valeurs non définies et les aplatir en une seule liste
        const allValues = dataVariables
        .filter(data => data !== undefined)
        .flatMap(data => data.flat());
    
        // Trouver le maximum parmi toutes les valeurs
        const max = Math.max(...allValues);
        if (max !== -Infinity) {
            setMaxBE(max)
        }
    }, [dataBE_1112_2D, dataBE_1113_2D, dataBE_1122_2D, dataBE_1123_2D, dataBE_114_2D, dataBE_115_2D, dataBE_118_2D, dataBE_1112_4D, dataBE_1113_4D, dataBE_1122_4D, dataBE_1123_4D, dataBE_114_4D, dataBE_115_4D, dataBE_118_4D]);

    function handleClickIRRIG(){
        setBIRRIG(true)
        setBESQ(false)
        setBCCult(false)
        setBCCep(false)
    }
    function handleClickESQ(){
        setBIRRIG(false)
        setBESQ(true)
        setBCCult(false)
        setBCCep(false)
    }
    function handleClickCCult(){
        setBIRRIG(false)
        setBESQ(false)
        setBCCult(true)
        setBCCep(false)
    }
    function handleClickCCep(){
        setBIRRIG(false)
        setBESQ(false)
        setBCCult(false)
        setBCCep(true)
    }
    function handleB2D(){
        setB2d("Checked")
        setB4d("UnChecked")
        if (TypeCulture === '1122') {
            setdataBE(dataBE_1122_2D)
            setdataBEC(dataBEC_1122_2D)
            setdataE(dataE_1122_2D)
        }
        else if (TypeCulture === '1123') {
            setdataBE(dataBE_1123_2D)
            setdataBEC(dataBEC_1123_2D)
            setdataE(dataE_1123_2D)
        }
        else if (TypeCulture === '1112') {
            setdataBE(dataBE_1112_2D)
            setdataBEC(dataBEC_1112_2D)
            setdataE(dataE_1112_2D)
        }
        else if (TypeCulture === '1113') {
            setdataBE(dataBE_1113_2D)
            setdataBEC(dataBEC_1113_2D)
            setdataE(dataE_1113_2D)
        }
        else if (TypeCulture === '114') {
            setdataBE(dataBE_114_2D)
            setdataBEC(dataBEC_114_2D)
            setdataE(dataE_114_2D)
        }
        else if (TypeCulture === '115') {
            setdataBE(dataBE_115_2D)
            setdataBEC(dataBEC_115_2D)
            setdataE(dataE_115_2D)
        }
        else {
            setdataBE(dataBE_118_2D)
            setdataBEC(dataBEC_118_2D)
            setdataE(dataE_118_2D)
        }
    }
    function handleB4D(){
        setB2d("UnChecked")
        setB4d("Checked")
        if (TypeCulture === '1122') {
            setdataBE(dataBE_1122_4D)
            setdataBEC(dataBEC_1122_4D)
            setdataE(dataE_1122_4D)
        }
        else if (TypeCulture === '1123') {
            setdataBE(dataBE_1123_4D)
            setdataBEC(dataBEC_1123_4D)
            setdataE(dataE_1123_4D)
        }
        else if (TypeCulture === '1112') {
            setdataBE(dataBE_1112_4D)
            setdataBEC(dataBEC_1112_4D)
            setdataE(dataE_1112_4D)
        }
        else if (TypeCulture === '1113') {
            setdataBE(dataBE_1113_4D)
            setdataBEC(dataBEC_1113_4D)
            setdataE(dataE_1113_4D)
        }
        else if (TypeCulture === '114') {
            setdataBE(dataBE_114_4D)
            setdataBEC(dataBEC_114_4D)
            setdataE(dataE_114_4D)
        }
        else if (TypeCulture === '115') {
            setdataBE(dataBE_115_4D)
            setdataBEC(dataBEC_115_4D)
            setdataE(dataE_115_4D)
        }
        else if (TypeCulture === '118') {
            setdataBE(dataBE_118_4D)
            setdataBEC(dataBEC_118_4D)
            setdataE(dataE_118_4D)
        }
        else if (TypeCulture === '222') {
            //setdataBE(dataBE_118_4D)
            //setdataBEC(dataBEC_118_4D)
            setdataE(dataE_222_4D)
        }
    }
    function handleChangeTypeCultureRemplacer (event){
        const selectedValue = event.target.value;
        setTypeCultureRemplacer(selectedValue);
        if (selectedValue === '1122') {
        }
        else if (selectedValue === '1123') {
        }
        else if (selectedValue === '1112') {
        }
        else if (selectedValue === '1113') {
        }
        else if (selectedValue === '114') {
        }
        else if (selectedValue === '115') {
            if(TypeCulturePar === '1112'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre de printemps\n16.43 ha","value":"16.43","itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre de printemps\n24055 ","value":24055,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(72658)
                setDataDifRisque_euros([1500,-700,2900])
                setDataDifRisque_pourcent([2,0.9,9])
            }
            else if(TypeCulturePar === '1113'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre d'hiver \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre d'hiver'\n24055 ","value":24055,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(72658)
                    setDataDifRisque_euros([1500,-700,2900])
                    setDataDifRisque_pourcent([1.5,-0.9,4.2])
            }
            else if(TypeCulturePar === '1122'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur de printemps \n16.43 ha","value":"16.43","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur de printemps \n19750 ","value":19750,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(68363)
                    setDataDifRisque_euros([400,500,-100])
                    setDataDifRisque_pourcent([-1,0.2,2])
            }
            else if(TypeCulturePar === '1123'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur d'hiver \n16.43 ha","value":"16.43","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur d'hiver \n19750 ","value":19750,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(72658)
                    setDataDifRisque_euros([350,200,-600])
                    setDataDifRisque_pourcent([4,1.2,-5])
            }
            else if(TypeCulturePar === '114'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge de printemps \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFF385"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge de printemps \n11396 ","value":11396,"itemStyle":{"color":"#FFF385"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(59999)
                    setDataDifRisque_euros([2150,500,3500])
                    setDataDifRisque_pourcent([7,5.5,12])
            }
            else if(TypeCulturePar === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n42.4 ha","value":"42.4","itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n34132 ","value":34132,"itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(110432)
                    setDataDifRisque_euros([1500,2300,3200])
                    setDataDifRisque_pourcent([1.9,3.1,-1])
            }
            else if(TypeCulturePar === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n22.42 ha","value":"22.42","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n59181 ","value":59181,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(100193)
                    setDataDifRisque_euros([-450,1200,2900])
                    setDataDifRisque_pourcent([-0.2,0.9,1.2])
            }
        }
        else if (selectedValue === '118') {
            if(TypeCulturePar === '1112'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre de printemps \n25.97 ha","value":"25.97","itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre de printemps \n33561 ","value":33561,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(76536)
                setDataDifRisque_euros([1500,500,3100])
                setDataDifRisque_pourcent([2,1.4,9])
            }
            else if(TypeCulturePar === '1113'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre d'hiver \n25.97 ha","value":"25.97","itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre d'hiver \n33561 ","value":33561,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(76536)
                    setDataDifRisque_euros([1500,-100,2700])
                    setDataDifRisque_pourcent([2,-0.4,8])
            }
            else if(TypeCulturePar === '1122'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur de printemps \n25.97 ha","value":"25.97","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur de printemps \n31217 ","value":31217,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(74192)
                setDataDifRisque_euros([-120,600,1700])
                setDataDifRisque_pourcent([-4,2,9])
            }
            else if(TypeCulturePar === '1123'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur d'hiver \n25.97 ha","value":"25.97","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur d'hiver \n31217 ","value":31217,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(74192)
                setDataDifRisque_euros([-130,700,2100])
                setDataDifRisque_pourcent([-4.1,2.3,11])
            }
            else if(TypeCulturePar === '114'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge de printemps \n25.97 ha","value":"25.97","itemStyle":{"color":"#FFF385"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge de printemps \n18073 ","value":18013,"itemStyle":{"color":"#FFF385"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(74192)
                setDataDifRisque_euros([-120,600,1700])
                setDataDifRisque_pourcent([-4,2,9])
            }
            else if(TypeCulturePar === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n42.4 ha","value":"42.4","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n41717 ","value":41717,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(69414)
                setDataDifRisque_euros([120,600,1700])
                setDataDifRisque_pourcent([4,2,9])
            }
            else if(TypeCulturePar === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n32.26 ha","value":"32.26","itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(74192)
                setDataDifRisque_euros([-120,600,1700])
                setDataDifRisque_pourcent([-4,2,9])
            }
        }
        else if (selectedValue === '222') {
            if(TypeCulturePar === '1112'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre de printemps \n6.29 ha","value":"6.29","itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre de printemps \n8129 ","value":8129,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                setPBTot2(64419)
                setDataDifRisque_euros([700,1500,-800])
                setDataDifRisque_pourcent([2,-5,3])
            }
            else if(TypeCulturePar === '1113'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre d'hiver \n6.29 ha","value":"6.29","itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre d'hiver \n8129 ","value":8129,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(64419)
                setDataDifRisque_euros([600,1500,-1100])
                setDataDifRisque_pourcent([1.8,-5,4.5])
            }
            else if(TypeCulturePar === '1122'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur de printemps \n6.29 ha","value":"6.29","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur de printemps \n7561 ","value":7561,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(63851)
                setDataDifRisque_euros([-700,1500,800])
                setDataDifRisque_pourcent([4,-5,-3])
            }
            else if(TypeCulturePar === '1123'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur d'hiver \n6.29 ha","value":"6.29","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur d'hiver \n7561 ","value":7561,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(63851)
                setDataDifRisque_euros([400,1500,800])
                setDataDifRisque_pourcent([2,-5,-3])
            }
            else if(TypeCulturePar === '114'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Orge de printemps \n6.29 ha","value":"6.29","itemStyle":{"color":"#FFF385"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Orge de printemps \n4363 ","value":4363,"itemStyle":{"color":"#FFF385"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(60653)
                setDataDifRisque_euros([-1500,2300,2500])
                setDataDifRisque_pourcent([-10,14,16])
            }
            else if(TypeCulturePar === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n32.26 ha","value":"32.26","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n25969 ","value":25969,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(61353)
                setDataDifRisque_euros([700,1100,1000])
                setDataDifRisque_pourcent([5,7,6])
            }
            else if(TypeCulturePar === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n22.72 ha","value":"22.72","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n27779 ","value":21779,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(68791)
                setDataDifRisque_euros([-1400,1900,1800])
                setDataDifRisque_pourcent([9,10,19.5])
            }
        }
    }
    function handleChangeTypeCulturePar (event){
        const selectedValue = event.target.value;
        setTypeCulturePar(selectedValue);
        if (selectedValue === '1112') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre de printemps\n16.43 ha","value":"16.43","itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre de printemps\n24055 ","value":24055,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(72658)
                setDataDifRisque_euros([1500,-700,2900])
                setDataDifRisque_pourcent([2,0.9,9])
            }
            else if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre de printemps \n25.97 ha","value":"25.97","itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre de printemps \n33561 ","value":33561,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(76536)
                setDataDifRisque_euros([1500,500,3100])
                setDataDifRisque_pourcent([2,1.4,9])
            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre de printemps \n6.29 ha","value":"6.29","itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre de printemps \n8129 ","value":8129,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(64419)
                setDataDifRisque_euros([700,1500,-800])
                setDataDifRisque_pourcent([2,-5,3])
            }

        }
        else if (selectedValue === '1113') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre d'hiver \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé tendre d'hiver'\n24055 ","value":24055,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(72658)
                    setDataDifRisque_euros([1500,-700,2900])
                    setDataDifRisque_pourcent([1.5,-0.9,4.2])
            }
            else if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre d'hiver \n25.97 ha","value":"25.97","itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé tendre d'hiver \n33561 ","value":33561,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(76536)
                    setDataDifRisque_euros([1500,-100,2700])
                    setDataDifRisque_pourcent([2,-0.4,8])
            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre d'hiver \n6.29 ha","value":"6.29","itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé tendre d'hiver \n8129 ","value":8129,"itemStyle":{"color":"#FFD705"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(64419)
                setDataDifRisque_euros([600,1500,-1100])
                setDataDifRisque_pourcent([1.8,-5,4.5])
            }
        }
        else if (selectedValue === '1122') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur de printemps \n16.43 ha","value":"16.43","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur de printemps \n19750 ","value":19750,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(68363)
                    setDataDifRisque_euros([400,500,-100])
                    setDataDifRisque_pourcent([-1,0.2,2])
            }
            else if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur de printemps \n25.97 ha","value":"25.97","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur de printemps \n31217 ","value":31217,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(74192)
                    setDataDifRisque_euros([-120,600,1700])
                    setDataDifRisque_pourcent([-4,2,9])
            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur de printemps \n6.29 ha","value":"6.29","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur de printemps \n7561 ","value":7561,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(63851)
                setDataDifRisque_euros([-700,1500,800])
                setDataDifRisque_pourcent([4,-5,-3])
            }
        }
        else if (selectedValue === '1123') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur d'hiver \n16.43 ha","value":"16.43","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Blé dur d'hiver \n19750 ","value":19750,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(72658)
                    setDataDifRisque_euros([350,200,-600])
                    setDataDifRisque_pourcent([4,1.2,-5])
            }
            else if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur d'hiver \n25.97 ha","value":"25.97","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Blé dur d'hiver \n31217 ","value":31217,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}]) 
                    setPBTot2(74192)
                setDataDifRisque_euros([-130,700,2100])
                setDataDifRisque_pourcent([-4.1,2.3,11])

            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur d'hiver \n6.29 ha","value":"6.29","itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Blé dur d'hiver \n7561 ","value":7561,"itemStyle":{"color":"#F8BE3E"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(63851)
                setDataDifRisque_euros([400,1500,800])
                setDataDifRisque_pourcent([2,-5,-3])
            }
        }
        else if (selectedValue === '114') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge de printemps \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFF385"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge de printemps \n11396 ","value":11396,"itemStyle":{"color":"#FFF385"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}]) 
                    setPBTot2(59999)
                    setDataDifRisque_euros([2150,500,3500])
                    setDataDifRisque_pourcent([7,5.5,12])   
            }
            else if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge de printemps \n25.97 ha","value":"25.97","itemStyle":{"color":"#FFF385"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge de printemps \n18073 ","value":18013,"itemStyle":{"color":"#FFF385"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(74192)
                setDataDifRisque_euros([-120,600,1700])
                setDataDifRisque_pourcent([-4,2,9])
            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Orge de printemps \n6.29 ha","value":"6.29","itemStyle":{"color":"#FFF385"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Orge de printemps \n4363 ","value":4363,"itemStyle":{"color":"#FFF385"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(60653)
                setDataDifRisque_euros([-1500,2300,2500])
                setDataDifRisque_pourcent([-10,14,16])

            }
        }
        else if (selectedValue === '115') {
            if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n42.4 ha","value":"42.4","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n41717 ","value":41717,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(69414)
                setDataDifRisque_euros([120,600,1700])
                setDataDifRisque_pourcent([4,2,9])
            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n22.72 ha","value":"22.72","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n27779 ","value":21779,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(68791)
                setDataDifRisque_euros([-1400,1900,1800])
                setDataDifRisque_pourcent([9,10,19.5])
            }
        }
        else if (selectedValue === '118') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n6.29 ha","value":"6.29","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n42.4 ha","value":"42.4","itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n34132 ","value":34132,"itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(110432)
                    setDataDifRisque_euros([1500,2300,3200])
                    setDataDifRisque_pourcent([1.9,3.1,-1])
            }
            else if (TypeCultureRemplacer === '222'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n32.26 ha","value":"32.26","itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Maïs\ngrain\n25969 ","value":25969,"itemStyle":{"color":"#92D050"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(61353)
                setDataDifRisque_euros([700,1100,1000])
                setDataDifRisque_pourcent([5,7,6])
            }
        }
        else if (selectedValue === '222') {
            if(TypeCultureRemplacer === '115'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n22.42 ha","value":"22.42","itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n25.97 ha","value":"25.97","itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n59181 ","value":59181,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Maïs\ngrain\n20906 ","value":20906,"itemStyle":{"color":"#92D050"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(100193)
                    setDataDifRisque_euros([-450,1200,2900])
                    setDataDifRisque_pourcent([-0.2,0.9,1.2])
            }
            else if (TypeCultureRemplacer === '118'){
                setMyDataSurf2(
                    [{"name":"Seigle\n6.74 ha","value":"6.74","itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n32.26 ha","value":"32.26","itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n16.43 ha","value":"16.43","itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n13.59 ha","value":"13.59","itemStyle":{"color":"#F4D9B6"}}])
                setMyDataPB2(
                    [{"name":"Seigle\n5509 ","value":5509,"itemStyle":{"color":"#E1884B"}},
                    {"name":"Colza \n7591 ","value":7591,"itemStyle":{"color":"#D7A657"}},
                    {"name":"Orge d’hiver et escourgeon \n15278 ","value":15278,"itemStyle":{"color":"#FFFF00"}},
                    {"name":"Tournesol\n14597 ","value":14597,"itemStyle":{"color":"#F4D9B6"}}])
                    setPBTot2(74192)
                    setDataDifRisque_euros([-120,600,1700])
                    setDataDifRisque_pourcent([-4,2,9])
            }
        }
    }


    function handleChangeTypeCulture(event) {
        var TC = event.target.value;
        setTypeCulture(TC)
        if (TC === '1122') {
            setdataELib(dataELib_1122)
            if (B2d === "Checked"){
                setdataBE(dataBE_1122_2D)
                setdataBEC(dataBEC_1122_2D)
                setdataE(dataE_1122_2D)
            }
            else {
                setdataBE(dataBE_1122_4D)
                setdataBEC(dataBEC_1122_4D)
                setdataE(dataE_1122_4D)
            }
        }
        else if (TC === '1123') {
            setdataELib(dataELib_1123)
            if (B2d === "Checked"){
                setdataBE(dataBE_1123_2D)
                setdataBEC(dataBEC_1123_2D)
                setdataE(dataE_1123_2D)

            }
            else {
                setdataBE(dataBE_1123_4D)
                setdataBEC(dataBEC_1123_4D)
                setdataE(dataE_1123_4D)
            }
        }
        else if (TC === '1112') {
            setdataELib(dataELib_1112)
            if (B2d === "Checked"){
                setdataBE(dataBE_1112_2D)
                setdataBEC(dataBEC_1112_2D)
                setdataE(dataE_1112_2D)
            }
            else {
                setdataBE(dataBE_1112_4D)
                setdataBEC(dataBEC_1112_4D)
                setdataE(dataE_1112_4D)
            }
        }
        else if (TC === '1113') {
            setdataELib(dataELib_1113)
            if (B2d === "Checked"){
                setdataBE(dataBE_1113_2D)
                setdataBEC(dataBEC_1113_2D)
                setdataE(dataE_1113_2D)
            }
            else {
                setdataBE(dataBE_1113_4D)
                setdataBEC(dataBEC_1113_4D)
                setdataE(dataE_1113_4D)
            }
        }
        else if (TC === '114') {
            setdataELib(dataELib_114)
            if (B2d === "Checked"){
                setdataBE(dataBE_114_2D)
                setdataBEC(dataBEC_114_2D)
                setdataE(dataE_114_2D)
            }
            else {
                setdataBE(dataBE_114_4D)
                setdataBEC(dataBEC_114_4D)
                setdataE(dataE_114_4D)
            }
        }
        else if (TC === '115') {
            setdataELib(dataELib_115)
            if (B2d === "Checked"){
                setdataBE(dataBE_115_2D)
                setdataBEC(dataBEC_115_2D)
                setdataE(dataE_115_2D)
            }
            else {
                setdataBE(dataBE_115_4D)
                setdataBEC(dataBEC_115_4D)
                setdataE(dataE_115_4D)
            }
        }
        else if (TC === '118') {
            setdataELib(dataELib_118)
            if (B2d === "Checked"){
                setdataBE(dataBE_118_2D)
                setdataBEC(dataBEC_118_2D)
                setdataE(dataE_118_2D)
            }
            else {
                setdataBE(dataBE_118_4D)
                setdataBEC(dataBEC_118_4D)
                setdataE(dataE_118_4D)
            }
        }
        else if (TC === '222') {
            setdataELib(dataELib_222)
            if (B2d === "Checked"){
                //setdataBE(dataBE_222_2D)
                //setdataBEC(dataBEC_222_2D)
                setdataE(dataE_222_2D)
            }
            else {
                //setdataBE(dataBE_222_4D)
                //setdataBEC(dataBEC_222_4D)
                setdataE(dataE_222_4D)
            }
        }
    }
    function handleChangeAnneeComp(event){
        setANNEE(event.target.value)
    }
    function presenceDUneProduction(data, rechercheValue){
        return data.some(objet => objet.name.includes(rechercheValue));
    }

    return (
        <div>
            <Entete titre="Solutions"/>
            <div className='container'>
                <div className='containerAleas'> 
                    <div className='titre' style={{textAlign:'center'}}> {BIRRIG ? "Irriguer ou ne pas irriguer ?" : BESQ ? "Modifier sa date de semis " : BCCult ? "Remplacer totalement une culture" : "Changer de cépage" } </div>
                    <div className='paragraphe' style={{textAlign:'center'}}> Commune : <span style={{fontWeight:'bold'}}>{CityName}</span></div>
                    <div className='paragraphe' style={{textAlign:'center'}}> Petite région agricole : <span style={{fontWeight:'bold'}}>{libellPRA}</span></div>
                    <div style={{display: BESQ || BCCult ? 'block' : 'none', textAlign:'center'}}>API en constructions</div>
                </div>
                
                <div className='buttonsFixedAleas'>
                    <div className={BIRRIG ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasIRRIG_AC': 'paragraphe ongletaléas_INAC OAléasIRRG_INAC'} onClick={handleClickIRRIG}> 
                        <img src={BIRRIG ? IRRIG_ac: IRRIG_inac} alt="Icone" className={BIRRIG ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BIRRIG ? (<div className='paragrapheAleas'>Irrigation</div>) : ""}
                    </div>
                    <div className={BESQ ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasESQ_AC': 'paragraphe ongletaléas_INAC OAléasESQ_INAC'} onClick={handleClickESQ}> 
                        <img src={BESQ ? ESQ_ac: ESQ_inac} alt="Icone" className={BESQ ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BESQ ? (<div className='paragrapheAleas'>Esquive </div>) : ""}
                    </div>
                    <div className={BCCult ? 'paragraphe ongletaléas_INAC ongletaléas_AC': 'paragraphe ongletaléas_INAC'} onClick={handleClickCCult}>   
                        <img src={BCCult ? CCult_ac: CCult_inac} alt="Icone" className={BCCult ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BCCult ? (<div className='paragrapheAleas'>Changement de culture</div>) : ""}
                    </div>
                    <div className={BCCep ? 'paragraphe ongletaléas_INAC ongletaléas_AC OAléasCCep_AC': 'paragraphe ongletaléas_INAC OAléasCCep_INAC'} onClick={handleClickCCep}>   
                        <img src={BCCep ? CCep_ac: CCep_inac} alt="Icone" className={BCCep ? 'ongletLOGOaléas_AC' : 'ongletLOGOaléas_INAC'}/> 
                        {BCCep ? (<div className='paragrapheAleas'>Changement de cépage</div>) : ""}
                    </div>
                    <div  style={{justifyContent:'flex-start', width:'100%', marginTop:'3px', display: !BCCep && !BCCult ? 'flex' : 'none'}}>
                        <button className={B2d==='UnChecked' ? 'buttonResi buttonEntite degre_Solution2050 buttonResiDefault' : 'buttonResi buttonEntite degre_Solution2050 button2050degreChecked'} style={{width:'100%', height:'25px', marginBottom:'0px'}} onClick={handleB2D}> +2°C </button>
                        <button className={B4d==='UnChecked' ? 'buttonResi buttonEntite degre_Solution2050 buttonResiDefault' : 'buttonResi buttonEntite degre_Solution2050 button2050degreChecked'} style={{width:'100%', height:'25px', marginBottom:'0px'}} onClick={handleB4D}> +4°C </button>
                    </div>
                    <div className='blocCultureSolutions' style={{display: !BCCep && !BCCult ? 'block' : 'none'}}> 
                        <div className='paragraphe' style={{margin:'0px', fontWeight:'bold'}}>Culture :</div>
                        <div className='row' onChange={handleChangeTypeCulture} style={{justifyContent:'flex-start',marginBottom:'0px', flexDirection:'column', alignItems:'flex-start'}}> 
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="1112" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "1112" } style={{width:'20px'}}/>  
                                <div className='paragrapheAleas texteCultureSolutions'> Blé tendre de printemps </div> </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="1113" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "1113"} style={{width:'20px'}}/>  
                                <div className='paragrapheAleas texteCultureSolutions'> Blé tendre d'hiver </div>
                            </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="1122" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "1122"} style={{width:'20px'}}/>  
                                <div className='paragrapheAleas texteCultureSolutions'> Blé dur de printemps </div>
                            </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="1123" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "1123"} style={{width:'20px'}}/>  
                                <div className='paragrapheAleas texteCultureSolutions'> Blé dur d'hiver </div>
                            </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="114" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "114"} style={{width:'20px'}}/>  
                                <div className='paragrapheAleas texteCultureSolutions'> Orge de printemps </div>
                            </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="115" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "115"} style={{width:'20px'}}/>  
                                <div className='paragrapheAleas texteCultureSolutions'> Orge d'hiver </div>
                            </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px'}}>
                                <input type="radio" value="118" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "118"} style={{width:'22px'}}/>  
                                <div className='paragrapheAleas' style={{ paddingLeft:'5%', fontWeight:'normal'}}> Maïs </div>
                            </div>
                            <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', display: BESQ ? 'flex' : 'none'}}>
                                <input type="radio" value="222" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "222"} style={{width:'20px'}}/>  
                                <div className='paragrapheAleas' style={{fontWeight:'normal'}}> Colza </div>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className='containerAleas'>
                    <div className={BIRRIG ? 'ShowResult' : 'HideResult'}>
                        {dataBE!== undefined   ? (
                        <div>
                            <div className='titreMoins' style={{textAlign:'center',marginTop:'5px'}}>Besoin en eau additionnel</div>
                            <div className='row 'style={{marginTop:'-20px'}}>
                                <GraphSolIrrig Data2020={dataBE[0]} Data2030={dataBE[1]} Data2040={dataBE[2]} Data2050={dataBE[3]} max={maxBE} />
                                <div className='titreMoins' style={{marginLeft:'30px'}}></div>
                            </div>
                        </div>
                        ) :(
                            <div className='row' style={{marginTop:'100px'}}>
                                <img src={loading_Data} alt="image de chargement" className=""/>
                            </div>
                        ) }
                         {/*<div className='row '>
                            <GraphSolIrrig DataTCo={dataBEC[0]} DataCo={dataBEC[1]} DataExc={dataBEC[2]} DataCa={dataBEC[3]} />
                            <div className='titreMoins' style={{marginLeft:'30px'}}>Besoin en eau cumulé</div>
                        </div> */}
                    </div>
                    <div className={BESQ ? 'ShowResult' : 'HideResult'}>
                        <GraphSolEsquive data={dataE} libelleData={dataELib} />
                    </div>
                    <div className={BCCult ? 'ShowResult' : 'HideResult'}> 
                        <div className={ShowResult ? 'ShowResult' : 'HideResult'}> 
                        <div className='blocCultureSolutions blocCultureCCult' > 
                            <div className='row' style={{justifyContent:'space-between'}}>
                                <div className='paragraphe' style={{marginLeft:'10%', fontWeight:'bold'}}>Remplacer</div>
                                <div className='paragraphe' style={{marginRight:'15%', fontWeight:'bold'}}>Par</div>
                            </div>
                            <div className='row' style={{justifyContent:'flex-start',marginBottom:'0px', flexDirection:'column', alignItems:'flex-start'}}> 
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="1112" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "1112" } style={{width:'33%'}} disabled={TypeCulturePar==="1112" || !presenceDUneProduction(myDataSurf1, "Blé tendre")? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Blé tendre de printemps </div> 
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="1112" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "1112" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="1112" ? true : false}/>  
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="1113" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "1113"} style={{width:'33%'}} disabled={TypeCulturePar==="1113" || !presenceDUneProduction(myDataSurf1, "Blé tendre") ? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Blé tendre d'hiver </div>
                                    <input onChange={handleChangeTypeCulturePar}  type="radio" value="1113" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "1113" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="1113" ? true : false}/> 
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="1122" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "1122"} style={{width:'33%'}} disabled={TypeCulturePar==="1122" || !presenceDUneProduction(myDataSurf1, "Blé dur") ? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Blé dur de printemps </div>
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="1122" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "1122" } style={{width:'33%'}}
                                    disabled={TypeCultureRemplacer==="1122" ? true : false}/> 
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="1123" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "1123"} style={{width:'33%'}}
                                    disabled={TypeCulturePar==="1123"|| !presenceDUneProduction(myDataSurf1, "Blé dur")  ? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Blé dur d'hiver </div>
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="1123" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "1123" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="1123" ? true : false}/> 
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input  onChange={handleChangeTypeCultureRemplacer} type="radio" value="114" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "114"} style={{width:'33%'}} disabled={TypeCulturePar==="114" || !presenceDUneProduction(myDataSurf1, "Orge de printemps")  ? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Orge de printemps </div>
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="114" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "114" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="114" ? true : false}/> 
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="115" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "115"} style={{width:'33%'}} disabled={TypeCulturePar==="115" || !presenceDUneProduction(myDataSurf1, "Orge d’hiver et escourgeon") ? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Orge d'hiver </div>
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="115" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "115" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="115" ? true : false}/> 
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="118" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "118"} style={{width:'33%'}} disabled={TypeCulturePar==="118" || !presenceDUneProduction(myDataSurf1, "Maïs")? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Maïs </div>
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="118" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "118" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="118" ? true : false}/> 
                                </div>
                                <div className='row' style={{justifyContent:'space-between',marginBottom:'0px', width:'100%'}}>
                                    <input onChange={handleChangeTypeCultureRemplacer} type="radio" value="222" name="CultARemplacer" className='input_radio' defaultChecked={TypeCultureRemplacer === "222"} style={{width:'33%'}} disabled={TypeCulturePar==="222"  || !presenceDUneProduction(myDataSurf1, "Colza")? true : false}/>  
                                    <div className='paragraphe' style={{width:'33%'}}> Colza </div>
                                    <input onChange={handleChangeTypeCulturePar} type="radio" value="222" name="CultRemplacerPar" className='input_radio' defaultChecked={TypeCulturePar === "222" } style={{width:'33%'}} disabled={TypeCultureRemplacer==="222" ? true : false}/> 
                                </div>
                            </div> 
                            </div>
                            <div className='titreMoins'> Assolements </div>
                            <div  className='dispLigne'>
                                <div className='column dispLeft grapheGaucheCompAssol'>
                                    <div className='titre'> Actuel <span style={{fontWeight:'normal', fontSize:'70%'}}> {surfTot1.toFixed(2)} ha </span> </div>
                                    <GraphAssolement3 myData={myDataSurf1} PB={""} print={false}/> 
                                </div>

                                <div className='column dispLeft'>
                                    <div className='titre'> Nouvel <span style={{fontWeight:'normal', fontSize:'70%'}}> {surfTot2.toFixed(2)} ha </span> </div> 
                                    <GraphAssolement3  myData={myDataSurf2} PB={""} print={false}/>
                                </div>
                            </div> 
                            
                            <div className='titreMoins'> Produits Bruts </div>
                            <div className='dispLigne'>
                                <div className='column dispLeft grapheGaucheCompAssol'>
                                    <div className='titre'> Actuel <span style={{fontWeight:'normal', fontSize:'70%'}}> {PBTot1.toFixed(0).toLocaleString()} € </span> </div>
                                    <GraphAssolement3 myData={myDataPB1} PB={""} print={false}/>
                                </div>

                                <div className='column dispLeft'>
                                    <div className='titre'> Nouvel <span style={{fontWeight:'normal', fontSize:'70%'}}> {PBTot2.toFixed(0).toLocaleString()} € </span> </div>
                                    <GraphAssolement3  myData={myDataPB2} PB={""} print={false}/>
                                </div>
                            </div> 

                            {/*<div style={{width:'30%', marginLeft:'33%', display:'flex', flexDirection:'column', marginRight:'5%'}}> 
                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <div className='paragraphe'>2025</div>
                                <div className='paragraphe'>2050</div>
                                </div>
                                <input style={{margin:'0px', height:'35px', width:'100%'}} type="range" id="cowbell" name="cowbell" min={2025} max={2050} value={ANNEE} step="5" onChange={handleChangeAnneeComp}/>
                                <div className='paragraphe' style={{marginLeft:ANNEE ==="2025" ? '0%' : ANNEE ==="2030" ? '15%' :  ANNEE ==="2035" ? "35%":  ANNEE ==="2040" ? "55%" :  ANNEE ==="2045" ? "75%" : "95%"}}> {ANNEE} </div>
                            </div>*/}

                            <div className='row' style={{ backgroundColor:'#D1FFA1'}}>
                                <div>
                                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                    <div className='titreMoins'>Avec votre nouvel assolement </div> 
                                    <div className='titreMoinsMoins' style={{color:'black', fontWeight:'bold',marginBottom:'10px', marginTop:'10px'}}> 
                                        {PBTot1 < PBTot2? 
                                        <div style={{fontWeight:'normal'}}> Le produit brut <span style={{fontWeight:'bold'}}>AUGMENTE de  {Math.abs(PBTot1-PBTot2).toLocaleString()} €</span></div> : 
                                        PBTot1 > PBTot2 ? 
                                        <div style={{fontWeight:'normal'}}> Le produit brut <span style={{fontWeight:'bold'}}>DIMINUE de  {Math.abs(PBTot1-PBTot2).toLocaleString()} €</span></div> : 
                                        <div style={{fontWeight:'normal'}}> Le produit brut <span style={{fontWeight:'bold'}}>RESTE STABLE</span></div>} 
                                    </div>
                                </div>
                                <div className='row' style={{marginTop:'20px', alignItems:'flex-start'}}>
                                    <div>
                                        <div className='paragraphe' style={{marginBottom:'10px', fontWeight:'bold'}} >Le risque <span style={{fontWeight:'bold', color:'#FABE06'}}>COURANT </span></div>
                                        <ul>
                                            <li style={{color:'black'}}><div className='paragraphe' style={{marginBottom:'10px'}}>
                                                {DataDifRisque_euros[0]< 0? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> AUGMENTE </span> en valeur de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_euros[0]).toLocaleString()} €</span></div> : 
                                                (DataDifRisque_euros[0] > 0) ? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> DIMINUE </span> en valeur de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_euros[0]).toLocaleString()} €</span></div> : 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> RESTE STABLE </span> en valeur </div>} 
                                            </div></li>
                                            <li style={{color:'black'}}><div className='paragraphe' style={{marginBottom:'20px'}}>
                                                {DataDifRisque_pourcent[0]< 0? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> AUGMENTE </span>en % de PB de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_pourcent[0]).toLocaleString()} %</span></div> : 
                                                (DataDifRisque_pourcent[0] > 0) ? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> DIMINUE </span> en % de PB de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_pourcent[0]).toLocaleString()} %</span></div> : 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> RESTE STABLE </span> en % de PB </div>} 
                                            </div></li>
                                        </ul>
                                    </div>
                                    <div style={{marginLeft:'30px', marginRight:'30px'}}>
                                        <div className='paragraphe' style={{marginBottom:'10px', fontWeight:'bold'}} >Le risque <span style={{fontWeight:'bold', color:'#0027D6'}}>EXCEPTIONNEL </span></div>
                                        <ul>
                                            <li style={{color:'black'}}><div className='paragraphe' style={{marginBottom:'10px'}}>
                                                {DataDifRisque_euros[1]< 0? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> AUGMENTE </span> en valeur de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_euros[1]).toLocaleString()} €</span></div> : 
                                                (DataDifRisque_euros[1] > 0) ? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> DIMINUE </span> en valeur de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_euros[1]).toLocaleString()} €</span></div> : 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> RESTE STABLE </span> en valeur </div>} 
                                            </div></li>
                                            <li style={{color:'black'}}><div className='paragraphe' style={{marginBottom:'20px'}}>
                                                {DataDifRisque_pourcent[1]< 0? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> AUGMENTE </span> en % de PB de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_pourcent[1]).toLocaleString()} %</span></div> : 
                                                (DataDifRisque_pourcent[1] > 0) ? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> DIMINUE </span> en % de PB de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_pourcent[1]).toLocaleString()} %</span></div> : 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> RESTE STABLE </span> en % de PB </div>} 
                                            </div></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className='paragraphe' style={{marginBottom:'10px', fontWeight:'bold'}} >Le risque <span style={{fontWeight:'bold', color:'#DB04B9'}}>CATASTROPHIQUE </span></div>
                                        <ul>
                                            <li style={{color:'black'}}><div className='paragraphe' style={{marginBottom:'10px'}}>
                                                {DataDifRisque_euros[2]< 0? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> AUGMENTE </span> en valeur de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_euros[2]).toLocaleString()} €</span></div> : 
                                                (DataDifRisque_euros[2] > 0) ? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> DIMINUE </span> en valeur de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_euros[2]).toLocaleString()} €</span></div> : 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> RESTE STABLE </span> en valeur </div>} 
                                            </div></li>
                                            <li style={{color:'black'}}><div className='paragraphe' style={{marginBottom:'20px'}}>
                                                {DataDifRisque_pourcent[2]< 0? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> AUGMENTE </span> en % de PB de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_pourcent[2]).toLocaleString()} %</span></div> : 
                                                (DataDifRisque_pourcent[2] > 0) ? 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> DIMINUE </span> en % de PB de <span style={{fontWeight:'bold'}}> {Math.abs(DataDifRisque_pourcent[2]).toLocaleString()} %</span></div> : 
                                                <div style={{fontWeight:'normal'}}><span style={{fontWeight:'bold'}}> RESTE STABLE </span> en % de PB de </div>} 
                                            </div></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className={BCCep ? 'ShowResult' : 'HideResult'} style={{marginTop:'-20px'}}>
                        {dataCCep !== undefined && dataCCepLib !== undefined ?
                            <GraphSolEsquiveViti data={dataCCep} libelleData={dataCCepLib} print={false}/> 
                             : 
                             <div className='row' style={{marginTop:'100px'}}>
                                <img src={loading_Data} alt="image de chargement" className=""/>
                            </div> 
                        }
                    </div>
                </div>
            </div>
            <ButtonQuestions/>
            <OADMenu idExploit={idExploit}/>
        </div>
    )
}

export default Solutions2050
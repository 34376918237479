import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphAleasNBjours2({myDatas, print}) { 
    
    const getOptions = () => {
      let options = {
        grid: {
          top: '20%',
          top: '20%',
        },
        legend: {
          data: [ "Nombre de jour de l'aléa"],
          textStyle: {
            fontSize: 16, // Ajustez la taille de la police de la légende selon vos besoins
          },
      },
        xAxis: {
            type: 'category',
            data: ['2020','2025','2030','2035', '2040', '2045', '2050'],
            name: 'Risques',
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              
            }
          },
          yAxis: {
            type: 'value',
            name: "Nombre de jour",
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [     
            {
              name: "Nombre de jour de l'aléa",
              data: myDatas,
              type: 'line',
              smooth: true,
              itemStyle: {
                color: 'purple'
              },
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(2)
                  },
                  position:'top',
                  textStyle: {
                    fontSize: 16 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
        ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:print === true ? "10px" : "20px", width: print === true ? '60%' : '80%', height: print === true ? '550px' :  '450px', marginTop:print === true ? '10px' : '30px'}}>
        <ReactEcharts option={getOptions()} style={{ height: '100%', width:'100%' }} />
    </div>
  
  );
}

export default GraphAleasNBjours2;
  